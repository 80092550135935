import request from '@/utils/request';

// 获取加油站基础数据信息
export function getSiteData() {
  return request({
    url: '/index/get_site_data',
    method: 'GET'
  });
}

// 智能自检列表
export function getSmartCheckData(params) {
  return request({
    url: '/smart_check/data',
    method: 'GET',
    params: params
  });
}

export function getLockMachines() {
  return request({
    url: '/index/lock_mackine',
    method: 'GET'
  });
}

// 加油机例行检查列表
export function getTankerCheckData() {
  return request({
    url: '/homeer_check',
    method: 'GET'
  });
}

// 告警记录数量
export function getWarningCountData() {
  return request({
    url: '/index/get_count_data',
    method: 'GET'
  });
}

// 智能自检异常表格
export function getSmartCheckUp() {
  return request({
    url: '/smart_check/data',
    params: {
      page: 1,
      limit: 7
    },
    method: 'GET'
  });
}

// 加油站列表
export function getSiteList(params) {
  return request({
    url: '/index/get_site_list',
    method: 'GET',
    params: params
  });
}

// 上月报警数量排行
export function getLockMachine(params) {
  return request({
    url: '/index/get_lock_machine_rank',
    method: 'GET',
    params: params
  });
}

// 销量排行TOP5
export function getSalesRank(params) {
  return request({
    url: '/index/sales_rank',
    method: 'GET',
    params: params
  });
}

// 历史油价
export function getHistoryPrice(params) {
  return request({
    url: '/index/history_price',
    method: 'GET',
    params: params
  });
}

// 报警数量排行
export function getAlarmCountRank(params) {
  return request({
    url: '/index/alarm_count_rank',
    method: 'GET',
    params: params
  });
}

// 加油站诚信等级
export function getCreditRating(params) {
  return request({
    url: '/index/credit_rating',
    method: 'GET',
    params: params
  });
}

// 可视化前台
// 油站油机数量
export function getDataVNewData(params) {
  return request({
    url: '/home/get_new_data',
    method: 'GET',
    params: params
  });
}

// 预警占比
export function getDataVAlarmCount(params) {
  return request({
    url: '/home/alarm_count',
    method: 'GET',
    params: params
  });
}

// 油品价格趋势
export function getDataVHistoryPrice(params) {
  return request({
    url: '/home/history_price',
    method: 'GET',
    params: params
  });
}

// 加油机例行检查/智能自检/每日出油量汇总
export function getDataVTankerCheck(params) {
  return request({
    url: '/home/tanker_check',
    method: 'GET',
    params: params
  });
}

// 加油站分布地图
export function getDataVSiteMap(params) {
  return request({
    url: '/home/site_map',
    method: 'GET',
    params: params
  });
}
