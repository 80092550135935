export function getIndicator(data, maxArr) {
  data = data || [];
  let arr = [];
  if (!data) return;
  for (let i = 0; i < data.length; i++) {
    arr.push({ name: data[i], max: maxArr[i] });
  }

  return arr;
}

export const getRadarMax = obj => {
  let arr = [];
  for (let i = 0; i < obj.last_data.length; i++) {
    // let max1 = obj.last.sort()
    // let max2 = obj.now.sort()
    // max1 = max1[max1.length - 1];
    // max2 = max2[max2.length - 1];
    // if(max1 >= max2) {
    //     arr.push(max1)
    // } else {
    //     arr.push(max2);
    // }
    let max = obj.last_data[i] + obj.current_data[i];
    // let max = obj.last[i]> obj.now[i]?obj.last[i]:obj.now[i];
    arr.push(max);
  }
  return arr;
};
