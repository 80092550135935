import * as echarts from 'echarts/core';
import { BarChart, PieChart, LineChart } from 'echarts/charts';
import Resize from '@/components/Chart/resize';
import { toPureObj } from '@/utils/utils';
import { getIndicator, getRadarMax } from '@/utils/echart_tools';
import _ from 'lodash';

import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  // DatasetComponentOption,
  TransformComponent
} from 'echarts/components';
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  PieChart,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer
]);

const textColor = '#D1D4F4';

const colors = ['#ffca3a', '#ff595e', '#8ac926', '#1982c4', '#6a4c93'];

const allOptions = {
  barOptions: dataArray => {
    let dataArr = toPureObj(dataArray)[0];
    // console.log(dataArr)
    return {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        // data: ['#95汽油', '#92汽油'],
        right: 40,
        top: 20,
        textStyle: {
          color: textColor
        }
      },
      grid: {
        bottom: 20,
        right: 40,
        left: 63,
        top: 70,
        containLabel: true
      },
      dataset: {
        source: dataArr
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          color: textColor
        },
        axisTick: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: '#fff'
          }
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#D1D4F4'
        },

        splitNumber: 4,
        splitLine: {
          lineStyle: {
            opacity: 0.3
          }
        },
        axisLine: {
          lineStyle: {
            color: 'none'
          }
        }
      },

      series: [
        {
          type: 'bar',
          itemStyle: {
            color: '#ffbb1d'
          },
          barWidth: 15
        },
        {
          type: 'bar',
          itemStyle: {
            color: '#536dfe'
          },
          barWidth: 15
        }
      ]
    };
  },
  circleOptions: value => {
    // const dataArr = toPureObj(value)[0];
    return {
      title: {
        text: '80%',
        x: 'center',
        y: 'center',
        textStyle: {
          fontWeight: 'normal',
          color: '#0580f2',
          fontSize: '0'
        }
      },
      color: ['rgba(176, 212, 251, 1)'],
      legend: {
        show: false,
        itemGap: 12,
        data: ['01', '02']
      },

      series: [
        {
          name: 'Line 1',
          type: 'pie',
          clockWise: true,
          radius: ['50%', '66%'],
          itemStyle: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          },
          hoverAnimation: false,
          data: [
            {
              value: value,
              name: '01',
              itemStyle: {
                color: 'rgba(83, 109, 254, 1)' // 0% 处的颜色
              }
            },
            {
              name: '02',
              value: 100 - value,
              itemStyle: {
                color: 'rgba(228, 233, 255, 1)' // 100% 处的颜色
              }
            }
          ]
        }
      ]
    };
  },
  areaLineOptions: dataArray => {
    const dataArr = toPureObj(dataArray)[0];

    return {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        // data: ['#95汽油', '#92汽油'],
        right: 40,
        top: 20,
        textStyle: {
          color: textColor
        }
      },
      grid: {
        bottom: 20,
        right: 40,
        left: 63,
        top: 70,
        containLabel: true
      },
      dataset: {
        source: dataArr
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          color: textColor
        },
        axisTick: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: '#fff'
          }
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#D1D4F4'
        },
        splitNumber: 4,
        splitLine: {
          lineStyle: {
            opacity: 0.3
          }
        },
        axisLine: {
          lineStyle: {
            color: 'none'
          }
        }
      },

      series: [
        {
          type: 'line',
          areaStyle: {},
          itemStyle: {
            color: '#ffbb1d'
          },
          smooth: true
        },
        {
          type: 'line',
          areaStyle: {},
          itemStyle: {
            color: '#536dfe'
          },
          smooth: true
        }
      ]
    };
  },
  lineOptions: dataArray => {
    const dataArr = toPureObj(dataArray)[0];

    // const series = dataArr;
    const seriesArr = _.filter(_.keys(dataArr), o => o !== 'time');

    let series = [];

    seriesArr.map((item, index) => {
      series.push({
        name: item,
        type: 'line',
        smooth: true,
        itemStyle: {
          color: colors[index]
        },
        emphasis: {
          itemStyle: {
            color: colors[index]
          }
        }
      });
    });

    return {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        // data: ['#95汽油', '#92汽油'],
        right: 40,
        top: 20,
        textStyle: {
          color: textColor
        }
      },
      grid: {
        bottom: 20,
        right: 40,
        left: 50,
        top: 70,
        containLabel: true
      },
      dataset: {
        source: dataArr
      },
      xAxis: {
        type: 'category',
        axisLabel: {
          color: textColor
        },
        axisTick: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: '#fff'
          }
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: '#D1D4F4'
        },
        // min: 4.0,
        // max: 8.0,
        splitNumber: 4,
        splitLine: {
          lineStyle: {
            opacity: 0.3
          }
        },
        axisLine: {
          lineStyle: {
            color: 'none'
          }
        }
      },
      // series
      series: [
        {
          type: 'line',
          itemStyle: {
            color: '#ffbb1d'
          },
          emphasis: {
            itemStyle: {
              color: '#ffbb1d'
            }
          }
        },
        {
          type: 'line',
          itemStyle: {
            color: '#536dfe'
          },
          emphasis: {
            itemStyle: {
              color: '#536dfe'
            }
          }
        }
      ]
    };
  },
  meanLineOptions: dataArray => {
    // console.log(JSON.stringify())
    let grid = [],
      legendData = dataArray[0].legendData;
    let h = Math.floor(90 / legendData.length);

    legendData.map((item, index) => {
      grid.push({ bottom: `${8 + h * index}%`, right: 20, height: `${h}%` });
    });
    console.log(grid);
    return {
      // legend: {
      //   top: 10,
      //   right: '20',
      //   textStyle: {
      //     color: '#2c3e50'
      //   },
      //   data: dataArray[0].legendData
      // },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        },
        formatter: function(params) {
          // console.log(params);
          let s = `${params[0].name}<br />`;
          params.map(item => {
            s += `${item.marker} ${item.seriesName}: ${item.value}<br />`;
          });
          return s;
        }
      },
      // grid: [
      //   { bottom: '8%', right: 20, height: '20%' },
      //   { bottom: '28%', right: 20, height: '20%' },
      //   { bottom: '48%', right: 20, height: '20%' },
      //   { bottom: '68%', right: 20, height: '20%' }
      //   // { top: '17%', height: '20%', tooltip: {} }
      // ],
      grid,
      xAxis: dataArray[0].xAxis,
      yAxis: dataArray[0].yAxis,
      series: dataArray[0].series
    };
  },
  randarOptions: dataArray => {
    // console.log(dataArray[0]);
    const dataArr = toPureObj(dataArray)[0];

    const lineStyle = {
      width: 1,
      opacity: 0.5
    };

    let maxArr = getRadarMax(dataArr);

    return {
      tooltip: {
        backgroundColor: '#ddd',
        textStyle: {
          color: '#26262F'
        }
      },
      grid: {
        bottom: 20
      },
      radar: {
        // indicator: dataArr[0],
        indicator: getIndicator(dataArr.legend, maxArr),
        // shape: 'circle',
        splitNumber: 4,
        nameGap: 8,
        name: {
          color: textColor,
          fontSize: 12
        },
        center: ['50%', '55%'],
        splitLine: {
          lineStyle: {
            color: [
              'rgba(86,90,131,0.1)',
              'rgba(86,90,131,0.2)',
              'rgba(86,90,131,0.4)',
              'rgba(86,90,131,0.6)',
              'rgba(86,90,131,0.8)',
              'rgba(86,90,131,1)'
            ].reverse()
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(86,90,131,0.5)'
            }
          }
        },
        splitArea: {
          show: false
        }
      },
      series: [
        {
          type: 'radar',
          lineStyle: lineStyle,
          symbolSize: 0,
          areaStyle: {
            opacity: 0.8
          },
          data: [
            {
              name: '本月',
              value: dataArr.current_data
            },
            {
              name: '上月',
              value: dataArr.last_data
            }
          ]
        }
      ],
      color: [
        new echarts.graphic.LinearGradient(
          0,
          0,
          1,
          0,
          [
            { offset: 0, color: 'rgba(62,70,152,0.8)' },
            { offset: 1, color: '#536DFE' }
          ],
          false
        ),
        new echarts.graphic.LinearGradient(
          0,
          0,
          1,
          0,
          [
            { offset: 0, color: 'rgba(255,168,62,0.8)' },
            { offset: 1, color: '#FFA83E' }
          ],
          false
        )
      ]
    };
  },
  pieOptions: dataArray => {
    const data = [];
    return {
      series: [
        {
          type: 'pie',
          radius: '90%',
          center: ['50%', '50%'],
          avoidLabelOverlap: true,
          color: [
            '#2EAFF0',
            '#00DE93',
            '#F0CA00',
            '#FF7362',
            '#A689EC',
            '#FF9B2D'
          ].filter((c, i) => dataArray[i] && data.push(dataArray[i])),
          data,
          startAngle: 130,
          label: {
            show: true,
            position: 'inside',
            formatter: '{c}%',
            color: '#ffffff',
            fontSize: 16
          }
        }
      ]
    };
  },
  pieCircleOptions: dataArray => {
    console.log(dataArray[0]);
    const data = dataArray[0] || [];
    const colors = ['#5571EB', '#FE8496'];
    let seriesData = data.map((item, index) => {
      return {
        value: item.value,
        name: item.name,
        itemStyle: {
          borderWidth: 4,
          borderColor: '#ffffff',
          color: colors[index]
        },
        label: {
          show: true,
          position: '',
          formatter: '{b}\n{c}%',
          color: colors[index],
          fontSize: 12,
          lineHeight: 16
        },
        labelLine: {
          show: false,
          length: 20,
          length2: 0
        }
      };
    });
    return {
      legend: {
        orient: 'vertical',
        right: 0,
        top: '45%',
        icon: 'circle',
        textStyle: {
          color: '#666666'
        }
      },
      grid: {
        top: 16,
        bottom: 16
      },
      tooltip: {
        trigger: 'item',
        transitionDuration: 0,
        formatter: '{a} <br /> {b}: {d}%'
      },
      series: [
        {
          name: '加油站检查',
          type: 'pie',
          clockWise: true,
          center: ['35%', '50%'],
          radius: ['30%', '60%'],
          // avoidLabelOverlap: true,
          hoverAnimation: false,
          data: seriesData,
          startAngle: 130
        }
      ]
    };
  },
  semiCircleOptions: dataArray => {
    const data = dataArray[0] || [];
    const colors = ['#DB97FF', '#01ADBE', '#2772DB'];
    var a = 0;
    var new_data = [];
    for (var i = 0; i < data.length; i++) {
      new_data[i] = data[i].value;
      data[i].value = 1;
      a += data[i].value;
    }
    data.push({
      value: a,
      name: '__other',
      itemStyle: { color: 'rgba(0,0,0,0)' }
    });

    return {
      tooltip: {
        trigger: 'none',
        formatter: '{a} <br/>{b} : {c} ({d}%)'
      },
      color: colors,
      animation: false,
      series: [
        {
          name: '',
          type: 'pie',
          startAngle: -180,
          radius: '100%',
          center: ['50%', '100%'],
          data: data,
          label: {
            position: 'inner',
            formatter: function(params) {
              if (params.name == '加油机数量') {
                return params.name + '\n ' + new_data[params.dataIndex];
              } else {
                return new_data[params.dataIndex] + '\n ' + params.name;
              }
            },
            textStyle: {
              color: '#fff',
              fontSize: 16,
              lineHeight: 25
            }
          },
          itemStyle: {
            show: false
          },
          hoverAnimation: false
        },
        {
          name: '',
          type: 'pie',
          startAngle: -180,
          clockWise: false, //顺时加载
          hoverAnimation: false, //鼠标移入变大
          center: ['50%', '100%'],
          radius: ['115%', '115%'],
          label: {
            show: false
          },
          itemStyle: {
            shadowBlur: 20,
            shadowOffsetX: 0,
            shadowColor: 'rgba(1,173,190,1)'
          },
          data: [
            {
              value: 0,
              name: '',
              itemStyle: {
                borderWidth: 2,
                borderColor: '#61bad3'
              }
            }
          ]
        }
      ]
    };
  },
  plantPieOptions: dataArray => {
    const color = [
      '#2D77F3',
      '#1BC99F',
      '#F4BA19',
      '#F83440',
      '#7221F6',
      '#F53980',
      '#EF6E18',
      '#21E7FF'
    ];
    // const data = [
    //   { value: 40, name: '监控设备离线' },
    //   { value: 38, name: '液位仪离线' },
    //   { value: 32, name: '计控主板变更' },
    //   { value: 30, name: '非法加油机' },
    //   { value: 28, name: '防作弊未开启' },
    //   { value: 26, name: '计量超差锁机' },
    //   { value: 22, name: '出油异常' },
    //   { value: 18, name: '端口离线' }
    // ];
    let data = [];
    dataArray[0].map(item => {
      data.push({ value: item.count, name: item.name });
    });

    return {
      tooltip: {
        trigger: 'item',
        transitionDuration: 0,
        formatter: '{a} <br /> {b}: {c}个 {d}%'
      },
      color,
      legend: {
        top: 'center',
        right: '20',
        orient: 'vertical',
        textStyle: {
          color: '#fff',
          lineHeight: 20
        },
        itemHeight: 10,
        itemWidth: 10,
        icon: 'circle'
        // itemStyle: {
        //   // color: {
        //   //   type: 'radial',
        //   //   x: 0.5,
        //   //   y: 0.5,
        //   //   r: 0.5,
        //   //   colorStops: [
        //   //     { offset: 0, color: 'red' },
        //   //     { offset: .8, color: 'black' }
        //   //   ],
        //   //   global: false // 缺省为 false
        //   // },
        //   // borderWidth: 1,
        //   // borderColor: color
        // }
      },
      series: [
        {
          name: '预警占比',
          type: 'pie',
          radius: [50, 85],
          center: ['35%', '50%'],
          avoidLabelOverlap: false,
          // roseType: 'radius',
          label: { show: false, position: 'center' },
          itemStyle: {
            borderRadius: 10,
            borderColor: '#00171B',
            borderWidth: 4
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '14',
              fontWeight: 'bold',
              color: 'white'
            }
          },
          data
        }
      ]
    };
  },
  plantTwoPieOptions: dataArray => {
    const color = ['#21E7FF', '#2D77F3'];
    return {
      tooltip: {
        trigger: 'item'
      },
      color,
      legend: {
        bottom: 'bottom',
        textStyle: {
          color: '#fff'
        },
        itemHeight: 10,
        itemWidth: 10
      },
      series: [
        {
          name: '数量',
          type: 'pie',
          radius: [50, 90],
          center: ['50%', '50%'],
          label: { show: false },
          data: dataArray[0]
        }
      ]
    };
  },
  plantLineOptions: dataArray => {
    const color = [
      '#2D77F3',
      '#1BC99F',
      '#F4BA19',
      '#F83440',
      '#7221F6',
      '#F53980',
      '#EF6E18',
      '#21E7FF'
    ];
    let series = [],
      yAxis = [];
    dataArray[0].oilList.forEach((item, index) => {
      series.push({
        name: item,
        type: 'line',
        step: 'middle',
        stack: `number${index}`,
        symbol: 'circle', //折点设定为实心点
        symbolSize: 7, //设定实心点的大小
        showSymbol: false,
        smooth: true,
        data: dataArray[0][`${item}data`],
        xAxisIndex: index,
        yAxisIndex: index
      });

      yAxis.push({
        gridIndex: index,
        type: 'value',
        name: '元 / L',
        nameTextStyle: {
          color: '#fff'
        },
        // splitNumber: 10,
        // max: _.maxBy(dataArray[0][`${item}data`]) + 0.5,
        min: _.minBy(dataArray[0][`${item}data`]) - 0.5,
        axisLabel: {
          color: '#21E7FF'
        },
        axisTick: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: '#21E7FF'
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            opacity: 0.2
          }
        }
      });
    });

    return {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        right: '20',
        textStyle: {
          color: '#fff'
        },
        itemHeight: 10,
        itemWidth: 10,
        icon: 'roundRect'
      },
      color,
      grid: [
        {
          top: '78%',
          left: '5%',
          right: '6%',
          bottom: '3%',
          containLabel: true
        },
        {
          top: '53%',
          left: '5%',
          right: '6%',
          bottom: '28%',
          containLabel: true
        },
        {
          top: '28%',
          left: '5%',
          right: '6%',
          bottom: '53%',
          containLabel: true
        },
        {
          left: '5%',
          right: '6%',
          top: '5%',
          bottom: '78%',
          containLabel: true
        }
      ],
      visualMap: [
        {
          show: false,
          type: 'continuous',
          seriesIndex: 0,
          min: 0,
          max: 400
        }
      ],
      xAxis: [
        {
          gridIndex: 0,
          type: 'category',
          boundaryGap: false,
          data: dataArray[0].date,
          axisLabel: {
            color: '#21E7FF'
          },
          axisLine: {
            lineStyle: {
              color: '#21E7FF'
            }
          },
          axisTick: {
            show: false
          }
        },
        {
          gridIndex: 1,
          type: 'category',
          boundaryGap: false,
          data: dataArray[0].date,
          axisLabel: {
            color: '#21E7FF'
          },
          axisLine: {
            lineStyle: {
              color: '#21E7FF'
            }
          },
          axisTick: {
            show: false
          }
        },
        {
          gridIndex: 2,
          type: 'category',
          boundaryGap: false,
          data: dataArray[0].date,
          axisLabel: {
            color: '#21E7FF'
          },
          axisLine: {
            lineStyle: {
              color: '#21E7FF'
            }
          },
          axisTick: {
            show: false
          }
        },
        {
          gridIndex: 3,
          type: 'category',
          boundaryGap: false,
          data: dataArray[0].date,
          axisLabel: {
            color: '#21E7FF'
          },
          axisLine: {
            lineStyle: {
              color: '#21E7FF'
            }
          },
          axisTick: {
            show: false
          }
        }
      ],
      yAxis: yAxis,
      series: series
      // [
      //     {
      //         name: '汽油',
      //         type: 'line',
      //         stack: '总量',
      //         symbol: 'circle',     //折点设定为实心点
      //         symbolSize: 7,   //设定实心点的大小
      //         data: [120, 132, 101, 134, 132, 101, 134, 90, 230, 210]
      //     },
      //     {
      //         name: '柴油',
      //         type: 'line',
      //         stack: '总量',
      //         symbol: 'circle',     //折点设定为实心点
      //         symbolSize: 7,   //设定实心点的大小
      //         data: [220, 182, 191, 234, 290, 330, 234, 290, 330, 310]
      //     },
      //     {
      //         name: '92#汽油',
      //         type: 'line',
      //         stack: '总量',
      //         symbol: 'circle',     //折点设定为实心点
      //         symbolSize: 7,   //设定实心点的大小
      //         data: [150, 232, 201, 154, 190, 201, 154, 190, 330, 410]
      //     }
      // ]
    };
  },
  plantBarOptions: dataArray => {
    // const color = ['#2D77F3', '#1BC99F', '#F4BA19', '#F83440', '#7221F6', '#F53980', '#EF6E18', '#21E7FF'];
    const legendData = [
      {
        name: '监控设备采集',
        enName: 'jk_Data',
        startColor: '#F4BA19',
        endColor: '#FF8000'
      },
      {
        name: '液位仪采集',
        enName: 'yw_Data',
        startColor: '#21E7FF',
        endColor: '#2D77F3'
      }
    ];
    let legend = [],
      series = [];
    legendData.map((item, index) => {
      console.log(index);
      legend.push(item.name);
      series.push({
        name: item.name,
        type: 'bar',
        data: dataArray[0][item.enName],
        itemStyle: { borderRadius: [2, 2, 0, 0] },
        barMaxWidth: 25,
        barMinWidth: 15,
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            { offset: 0, color: item.startColor },
            { offset: 1, color: item.endColor }
          ],
          globalCoord: false // 缺省为 false
        }
      });
    });
    return {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: legend,
        right: '20',
        textStyle: {
          color: '#fff'
        },
        itemHeight: 10,
        itemWidth: 10,
        icon: 'roundRect'
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        // boundaryGap: false,
        data: dataArray[0].date,
        axisLabel: {
          color: '#21E7FF'
        },
        axisLine: {
          lineStyle: {
            color: '#21E7FF'
          }
        },
        axisTick: {
          show: false
        }
      },
      yAxis: {
        type: 'value',
        name: '单位：L',
        nameTextStyle: {
          color: '#fff'
        },
        axisLabel: {
          color: '#21E7FF'
        },
        axisTick: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: '#21E7FF'
          }
        },
        splitLine: {
          show: false
        }
      },
      series
      // [
      //     {
      //         name: '监控设备采集',
      //         type: 'bar',
      //         data: [120, 132, 101, 134, 132, 101, 134, 90, 230, 210],
      //         itemStyle: {
      //             borderRadius: 50
      //         },
      //         color: {
      //             type: 'linear',
      //             x: 0,
      //             y: 0,
      //             x2: 0,
      //             y2: 1,
      //             colorStops: [{
      //                 offset: 0, color: '#F4BA19' // 0% 处的颜色
      //             }, {
      //                 offset: 1, color: '#FF8000' // 100% 处的颜色
      //             }],
      //             globalCoord: false // 缺省为 false
      //         }
      //     },
      //     {
      //         name: '液位仪采集',
      //         type: 'bar',
      //         data: [220, 182, 191, 234, 290, 330, 234, 290, 330, 310],
      //         itemStyle: {
      //             borderRadius: 50
      //         },
      //         color: {
      //             type: 'linear',
      //             x: 0,
      //             y: 0,
      //             x2: 0,
      //             y2: 1,
      //             colorStops: [{
      //                 offset: 0, color: '#21E7FF' // 0% 处的颜色
      //             }, {
      //                 offset: 1, color: '#2D77F3' // 100% 处的颜色
      //             }],
      //             globalCoord: false // 缺省为 false
      //         }
      //     }
      // ]
    };
  }
};

export default (el, classify, ...args) => {
  if (el) {
    const instance = echarts.init(el);
    instance.clear();
    // instance.showLoading();
    // console.log(args)
    instance.setOption(allOptions[`${classify}Options`](args), true);
    instance.resize();

    window.addEventListener(
      'resize',
      () => {
        console.log('resize');
        instance.resize();
      },
      false
    );

    Resize.$on('resizeEchart', () => {
      setTimeout(() => {
        instance.resize();
      }, 300);
    });

    return instance;
  }
};
